<template>
  <div>
    <div class="row maindashboard">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              일일 안전작업허가 목록
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="workPermit"
                  :isFirstValue="false"
                  label=""
                  v-model="plant.workPermit"
                  @datachange="getWorkPermits"/>
              </div>
            </div>
            <div class="card-body nopadgrid">
              <c-table
                ref="gridWorkPermit"
                tableId="gridWorkPermit"
                :isTitle="false"
                :isDashboard="true"
                :columnSetting="false"
                :usePaging="false"
                :filtering="false"
                :isFullScreen="false"
                :hideBottom="true"
                gridHeight="470px"
                :columns="gridWorkPermit.columns"
                :data="gridWorkPermit.data"
                @linkClick="openWorkPermitPop"
              >
              </c-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="cardcontents directorMainLayer">
          <div class="card-header non-label-icon mapheader">
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==1"
              :color="mapObj.activeTask===1 ? 'green-6' : 'gray-4'" 
              :text-color="mapObj.activeTask===1 ? 'white' : 'white'" 
              icon="directions"
              @click="() => mapObj.activeTask = 1">
              <!-- 작업허가서 -->
              {{setLabel('LBL0000214')}}
            </q-chip>
            <div class="card-more main-header-input main-header-input2 main-header-input2-director">
              <c-datepicker
                v-show="mapObj.activeTask===1"
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
            </div>
          </div>
          <div class="card-body" ref="carousel" style="padding: 15px !important;">
            <q-carousel
              v-if="mapObj.maps && mapObj.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              control-type="regular"
              control-color="grey-6"
              v-model="mapObj.slide"
              infinite
            >
              <q-carousel-slide 
                v-for="(map, idx) in mapObj.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <!-- <div class="mapTitleDivDirector" v-text="map.mapName"></div> -->
                    <!-- 작업허가서 -->
                    <template v-if="mapObj.activeTask===1">
                      <!-- 직접 위치를 선택한 marked -->
                      <VueDraggableResizable
                        v-for="(item, idx) in map.maps"
                        :key="idx"
                        ref="markImage"
                        class="mainMarkImage"
                        :class="item.idx === mapObj.activeMarked ? 'selected' : ''"
                        :resizable="false"
                        :parent="true"
                        :draggable="false"
                        :x="item.locationXcoordinate * mapRate"
                        :y="item.locationYcoordinate * mapRate"
                        :w="40 " :h="40"
                        :grid="[20, 20]"
                        @activated="onMarkedInfo(item, 'I')"
                      >
                        <c-map-marker :isSelected="item.idx === mapObj.activeMarked" />
                        <q-menu 
                          :ref="'markedproxy' + item.idx" 
                          :content-class="'mainMapMenuLayer'"
                          anchor="top right"
                          self="top left">
                          <q-card 
                            v-for="(workPermit, idx) in mapObj.selectedWorkPermit.workPermits"
                            :key="idx"
                            flat bordered 
                            @click="openWorkPermitPop(workPermit)"
                            :class="['main-work-permit-card main-work-permit-card-cursor', setStepClass(workPermit.swpStepCd)]">
                            <q-item class="main-work-permit-card-section main-work-permit-card-item">
                              <q-item-section avatar>
                                <q-badge rounded :color="setStepColor(workPermit.swpStepCd)" :label="workPermit.swpStepName" />
                              </q-item-section>

                              <q-item-section>
                                <q-item-label>
                                  <q-icon 
                                    v-for="(icon, iconIdx) in setTypeIcons(workPermit.sopWorkTypeCds)"
                                    :key="iconIdx"
                                    :name="icon" 
                                    class="text-red"
                                    size="25px" />
                                </q-item-label>
                              </q-item-section>
                            </q-item>
                            <q-card-section class="main-work-permit-card-section">
                              <q-icon 
                                name="chevron_right" 
                                class="text-indigo" 
                              />
                              <b>{{workPermit.workSummary}}</b> ({{workPermit.workTime}})
                            </q-card-section>
                            <q-card-section class="main-work-permit-card-section" v-if="workPermit.vendorName">
                              <q-icon 
                                name="chevron_right" 
                                class="text-indigo" 
                              /><b>작업업체 : {{workPermit.vendorName}}</b>
                            </q-card-section>
                            <q-card-section class="main-work-permit-card-section" v-if="workPermit.processName || workPermit.equipmentName">
                              <div v-if="workPermit.processName" class="main-work-permit-card-proc">
                                <q-chip
                                  outline square
                                  color="blue"
                                  text-color="white">
                                  {{workPermit.processName}}
                                </q-chip>
                              </div>
                              <div v-if="workPermit.equipmentName" class="main-work-permit-card-equip">
                                <q-chip
                                  outline square
                                  color="green"
                                  text-color="white">
                                  {{workPermit.equipmentName}}
                                </q-chip>
                              </div>
                            </q-card-section>
                          </q-card>
                        </q-menu>
                      </VueDraggableResizable>
                      <!-- 작업장소 marked -->
                      <VueDraggableResizable
                        v-for="(location, idx) in map.otherMaps"
                        :key="'other' + idx"
                        :ref="'locationMark' + location.mdmLocationId"
                        class="mainMarkImage"
                        :class="location.idx === mapObj.activeMarked ? 'selected' : ''"
                        style="z-index:1"
                        :parent="true"
                        :draggable="false"
                        :resizable="false"
                        :x="location.x * mapRate * mapObj.ratio"
                        :y="location.y * mapRate * mapObj.ratio"
                        :w="40 " :h="40"
                        :grid="[20, 20]"
                        @activated="onMarkedInfo(location, 'L')"
                      >
                        <c-map-marker :isSelected="location.idx === mapObj.activeMarked" />
                        <q-menu 
                          :ref="'markedproxy' + location.idx" 
                          :content-class="'mainMapMenuLayer'"
                          anchor="top right"
                          self="top left">
                          <q-card 
                            v-for="(workPermit, idx) in mapObj.selectedWorkPermit.workPermits"
                            :key="idx"
                            flat bordered 
                            @click="openWorkPermitPop(workPermit)"
                            :class="['main-work-permit-card main-work-permit-card-cursor', setStepClass(workPermit.swpStepCd)]">
                            <q-item class="main-work-permit-card-section main-work-permit-card-item">
                              <q-item-section avatar>
                                <q-badge rounded :color="setStepColor(workPermit.swpStepCd)" :label="workPermit.swpStepName" />
                              </q-item-section>

                              <q-item-section>
                                <q-item-label>
                                  <q-icon 
                                    v-for="(icon, iconIdx) in setTypeIcons(workPermit.sopWorkTypeCds)"
                                    :key="iconIdx"
                                    :name="icon" 
                                    class="text-red"
                                    size="25px" />
                                </q-item-label>
                              </q-item-section>
                            </q-item>
                            <q-card-section class="main-work-permit-card-section">
                              <q-icon 
                                name="chevron_right" 
                                class="text-indigo" 
                              />
                              <b>{{workPermit.workSummary}}</b> ({{workPermit.workTime}})
                            </q-card-section>
                            <q-card-section class="main-work-permit-card-section" v-if="workPermit.vendorName">
                              <q-icon 
                                name="chevron_right" 
                                class="text-indigo" 
                              /><b>작업업체 : {{workPermit.vendorName}}</b>
                            </q-card-section>
                            <q-card-section class="main-work-permit-card-section" v-if="workPermit.processName || workPermit.equipmentName">
                              <div v-if="workPermit.processName" class="main-work-permit-card-proc">
                                <q-chip
                                  outline square
                                  color="blue"
                                  text-color="white">
                                  {{workPermit.processName}}
                                </q-chip>
                              </div>
                              <div v-if="workPermit.equipmentName" class="main-work-permit-card-equip">
                                <q-chip
                                  outline square
                                  color="green"
                                  text-color="white">
                                  {{workPermit.equipmentName}}
                                </q-chip>
                              </div>
                            </q-card-section>
                          </q-card>
                        </q-menu>
                      </VueDraggableResizable>
                    </template>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 등록된 지도가 없습니다. -->
            <el-empty v-else 
              :image-size="435"
              :description="$comm.getLangMessage('MSG0000122')"></el-empty>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              하역/선적/출하 현황
              <!-- <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="unloadShip"
                  :isFirstValue="false"
                  label=""
                  v-model="plant.unloadShip"
                  @datachange="getUnloadShips"/>
              </div> -->
            </div>
            <div class="card-body nopadgrid">
              <c-table
                ref="gridUnload"
                tableId="gridUnload"
                :isTitle="false"
                :isDashboard="true"
                :columnSetting="false"
                :usePaging="false"
                :filtering="false"
                :isFullScreen="false"
                :hideBottom="true"
                gridHeight="140px"
                :columns="gridUnload.columns"
                :data="gridUnload.data"
              >
                <template v-slot:customArea="{ props, col }">
                  <template v-if="col.name === 'mdmUnloadTypeName'">
                    <q-chip
                      :color="props.row.mdmUnloadTypeName == '하역' ? 'orange' : 'blue'"
                      outline square
                      class="full-size-chip"
                      text-color="white">
                      {{props.row.mdmUnloadTypeName}}
                    </q-chip>
                  </template>
                </template>
              </c-table>
            </div>
            <div class="card-body nopadgrid">
              <c-table
                ref="gridShip"
                tableId="gridShip"
                :isTitle="false"
                :isDashboard="true"
                :columnSetting="false"
                :usePaging="false"
                :filtering="false"
                :isFullScreen="false"
                :hideBottom="true"
                gridHeight="120px"
                :columns="gridShip.columns"
                :data="gridShip.data"
              >
              </c-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              Work Order 현황
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="workOrder"
                  :isFirstValue="false"
                  label=""
                  v-model="plant.workOrder"
                  @datachange="getWorkOrders"/>
              </div>
            </div>
            <div class="card-body nopadgrid">
              <c-table
                ref="gridWorkOrder"
                tableId="gridWorkOrder"
                :isTitle="false"
                :isDashboard="true"
                :columnSetting="false"
                :usePaging="false"
                :filtering="false"
                :isFullScreen="false"
                :hideBottom="true"
                gridHeight="280px"
                :columns="gridWorkOrder.columns"
                :data="gridWorkOrder.data"
                @linkClick="openWorkOrderPop"
              >
                <template v-slot:customArea="{ props, col }">
                  <template v-if="col.name === 'woWorkPlanStepCd'">
                    <q-chip
                      :color="getWorkOrderColor(props.row.woWorkPlanStepCd)"
                      outline square
                      class="full-size-chip"
                      :class="props.row.woWorkPlanStepCd == 'WWPSC00000' ? 'blinking' : ''"
                      text-color="white">
                      {{props.row.woWorkPlanStepName}}
                    </q-chip>
                  </template>
                  <template v-if="col.name === 'equipmentTypeName'">
                    <q-chip
                      outline square 
                      color="blue"
                      text-color="white"
                      class="q-ma-none customchipdept full-size-chip">
                      {{props.row['equipmentTypeName']}}
                    </q-chip>
                  </template>
                  <template v-if="col.name === 'emergencyFlagName'">
                    <q-chip
                      v-if="props.row['emergencyFlagName'] == '긴급'"
                      outline square 
                      color="red"
                      text-color="white"
                      class="q-ma-none customchipdept">
                      {{props.row['emergencyFlagName']}}
                    </q-chip>
                    <span v-else>{{props.row['emergencyFlagName']}}</span>
                    <q-badge v-if="props.row.delayFlag=='Y'" color="negative" label="지연" class="blinking" />
                  </template>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-notice-popup></c-notice-popup>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import mixinConvert from '@/js/mixin-template-convert'
export default {
  name: 'main-dashboard-director',
  mixins: [mixinConvert],
  components: { 
    VueDraggableResizable,
  },
  props: {
  },
  data() {
    return {
      plant: {
        workPermit: null,
        unloadShip: null,
        workOrder: null,
      },
      gridWorkPermit: {
        listUrl: '',
        columns: [
          {
            // 작업개요
            name: 'workSummary',
            field: 'workSummary',
            label: 'LBL0000211',
            align: 'left',
            sortable: false,
            type: 'link',
            style: 'width:170px',
          },
          {
            name: 'typeEquipmentName',
            field: 'typeEquipmentName',
            label: '설비명',
            align: 'left',
            style: 'width:140px',
            sortable: false,
          },
          {
            // 작업업체
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            sortable: false,
            style: 'width:85px',
          },
          {
            // 보충작업
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            label: 'LBL0000205',
            align: 'left',
            sortable: false,
            style: 'width:130px',
          },
          // {
          //   // 신청부서
          //   name: 'approvalDeptName',
          //   field: 'approvalDeptName',
          //   label: 'LBL0000202',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width:80px',
          // },
        ],
        data: [],
      },
      gridUnload: {
        listUrl: '',
        columns: [
          {
            name: 'mdmUnloadTypeName',
            field: 'mdmUnloadTypeName',
            label: '하역/선적',
            align: 'center',
            type: 'custom',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'ownerName',
            field: 'ownerName',
            label: '화주사',
            align: 'left',
            sortable: false,
          },
          {
            name: 'shipName',
            field: 'shipName',
            label: '선명',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workQty',
            field: 'workQty',
            label: '작업량',
            align: 'center',
            type: 'cost',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'startDt',
            field: 'startDt',
            label: '시작일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'endDt',
            field: 'endDt',
            label: '종료일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
      },
      gridShip: {
        listUrl: '',
        columns: [
          {
            name: 'mdmShippingTypeName',
            field: 'mdmShippingTypeName',
            label: '출하구분',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'workDt',
            field: 'workDt',
            label: '출하일자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ownerName',
            field: 'ownerName',
            label: '화주사',
            align: 'left',
            sortable: false,
          },
          {
            name: 'workQty',
            field: 'workQty',
            label: '작업량',
            align: 'center',
            type: 'cost',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'daynight',
            field: 'daynight',
            label: '주/야',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
        ],
        data: [],
      },
      gridWorkOrder: {
        listUrl: '',
        columns: [
          {
            name: 'woWorkPlanStepCd',
            field: 'woWorkPlanStepCd',
            label: '진행상태',
            align: 'center',
            type: 'custom',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'emergencyFlagName',
            field: 'emergencyFlagName',
            label: '구분',
            align: 'center',
            sortable: false,
            type: 'custom',
            style: 'width:110px',
          },
          {
            name: 'workPlanName',
            field: 'workPlanName',
            label: '정비오더명',
            align: 'left',
            sortable: false,
            style: 'width:250px',
            type: 'link'
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            type: 'custom',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            style: 'width:220px',
            sortable: false,
          },
          {
            name: 'reqDeptName',
            field: 'reqDeptName',
            label: '정비요청부서',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'woRequestDt',
            field: 'woRequestDt',
            label: '정비요청일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
      },
      mapObj: {
        activeTask: 1,
        activeMarked: 0,
        selectedWorkPermit: {
          workPermits: [],
        },
        envUrl: '',
        markInfoUrl: '',
        url: '',
        taskParam: {
          workPermitPeriod: [],
        },
        location: {
          mdmLocationId: '',
          locationName: '',
          depts: [],
          chemprods: [],
          equips: [],
          hazardChems: [],
        },
        maps: [],
        orignMaps: [],
        slide: 0,
        height: 800,
        ratio: 1,
        orignRatio: 1,
        endIdx: 1,
        loading: false,
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 0.835
    },
  },
  methods: {
    init() {
      this.gridWorkPermit.listUrl = selectConfig.sop.swp.workPermit.list.url;
      this.gridUnload.listUrl = selectConfig.mdm.unload.list.url;
      this.gridShip.listUrl = selectConfig.mdm.shipping.list.url;
      this.gridWorkOrder.listUrl = selectConfig.wod.workorder.plan.list.url;
      this.mapObj.url = selectConfig.main.map.url
      this.mapObj.processUrl = selectConfig.main.mapProcess.url
      this.mapObj.envUrl = selectConfig.main.env.url
      this.getWorkPermits();
      this.getUnloads();
      this.getShips();
      this.getWorkOrders();
      this.getMapPriodParam();
      this.getMapData();
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
      },);
    },
    getMapData(loading) {
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
      }
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          /**
           * 지도는 1개
           */
          this.$_.forEach(_result.data, item => {
            this.mapObj.endIdx = 1;
            if (item.otherMaps && item.otherMaps.length > 0) {
              this.$_.forEach(item.otherMaps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
            if (item.maps && item.maps.length > 0) {
              this.$_.forEach(item.maps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
          })

          this.mapObj.maps = this.$_.cloneDeep(_result.data)
          this.mapObj.orignRatio = this.mapObj.maps[0].ratio;
          
          this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[0].ratio);
          
          this.mapObj.height = 550
          let resizeRatio = Math.round(this.mapObj.height / 800 * 100) / 100;
          let _height = this.mapObj.height * this.mapRate
          this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
          this.mapObj.ratio = resizeRatio;

          // 로딩바 제거
          if (loading) {
            this.mapObj.loading = false;
          }
        }
      },);
    },
    onMarkedInfo(markedInfo, flag) {
      if (flag === 'I') {
        // 직접 위치를 지정한 marked
        this.$set(this.mapObj.selectedWorkPermit, 'workPermits', markedInfo.resultTypes);
      } else { 
        // 작업장소에 따른 위치 marked
        this.$set(this.mapObj.selectedWorkPermit, 'workPermits', markedInfo.tasks);
      }
      this.$set(this.mapObj, 'activeMarked', markedInfo.idx)
    },
    /* eslint-disable no-unused-vars */
    setStepColor(swpStepCd) {
      let color = '';
      switch(swpStepCd) {
        case 'SS00000005':
          color = 'light-blue';
          break;
        case 'SS00000010':
          color = 'amber';
          break;
        case 'SS00000020':
          color = 'light-green';
          break;
      }
      return color;
    },
    setStepClass(swpStepCd) {
      let _class = '';
      switch(swpStepCd) {
        case 'SS00000005':
        _class = 'main-work-permit-card-light-blue';
          break;
        case 'SS00000010':
        _class = 'main-work-permit-card-amber';
          break;
        case 'SS00000020':
        _class = 'main-work-permit-card-light-green';
          break;
      }
      return _class;
    },
    setTypeIcons(sopWorkTypeCds) {
      let icons = [];
      let typeArray = sopWorkTypeCds && sopWorkTypeCds.length > 0 ? sopWorkTypeCds.split(',') : [];
      if (typeArray && typeArray.length > 0) {
        this.$_.forEach(typeArray, type => {
          switch(type) {
            case 'SPT0000001': // 일반
              icons.push('engineering');
              break;
            case 'SPT0000005': // 화기
              icons.push('local_fire_department');
              break;
            case 'SSWT000001': // 밀폐공간
              icons.push('dangerous');
              break;
            case 'SSWT000005': // 정전
              icons.push('flash_off');
              break;
            case 'SSWT000010': // 방사선
              icons.push('warning');
              break;
            case 'SSWT000015': // 굴착
              icons.push('hardware');
              break;
            case 'SSWT000020': // 고소
              icons.push('stairs');
              break;
            case 'SSWT000025': // 중장비
              icons.push('agriculture');
              break;
          }
        })
      }
      return icons;
    },
    getWorkPermits() {
      this.$http.url = this.gridWorkPermit.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plant.workPermit,
        permitDates: [this.$comm.getToday(), this.$comm.getToday()]
      }
      this.$http.request((_result) => {
        this.gridWorkPermit.data = _result.data
      },);
    },
    openWorkPermitPop(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePermitPopup;
    },
    closePermitPopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openWorkOrderPop(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = '정비오더 상세';
      this.popupOptions.suffixChip = item ? item.woWorkPlanStepName : '';
      this.popupOptions.param = {
        workPlanId: item ? item.workPlanId : '',
        workResultId: item ? item.workResultId : '',
        plantCd: item ? item.plantCd : '',
        woWorkPlanStepCd: item ? item.woWorkPlanStepCd : 'WWPSC00001',
        woRequestId: item ? item.woRequestId : '',
        copyFlag: item ? item.copyFlag : 'N',
        firstCopy: item ? (item.firstCopy ? item.firstCopy : false) : false,
      };
      this.popupOptions.target = () => import(`${'@/pages/wod/plan/workOrderPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeOrderPopup;
    },
    closeOrderPopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    getUnloadShips() {
      this.getUnloads();
      this.getShips();
    },
    getUnloads() {
      this.$http.url = this.gridUnload.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plant.unloadShip,
        searchDt: this.$comm.getToday()
      }
      this.$http.request((_result) => {
        this.gridUnload.data = _result.data
      },);
    },
    getShips() {
      this.$http.url = this.gridShip.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plant.unloadShip,
        // dts: ['2021-02-05', '2024-02-05']
        dts: [this.$comm.getToday(), this.$comm.getToday()]
      }
      this.$http.request((_result) => {
        this.gridShip.data = _result.data
      },);
    },
    getWorkOrders() {
      this.$http.url = this.gridWorkOrder.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plant.workOrder,
        // plantDts: ['2021-02-05', '2024-02-05']
        plantDts: [this.$comm.getToday(), this.$comm.getToday()]
      }
      this.$http.request((_result) => {
        this.gridWorkOrder.data = _result.data
      },);
    },
    getWorkOrderColor(_code) {
      let returnColor = '';
      switch(_code) {
        case 'WWPSC00000': // 정비요청
          returnColor = 'grey-6';
          break;
        case 'WWPSC00001': // 작업계획중
          returnColor = 'orange';
          break;
        case 'WWPSC00002': // 작업계획 승인중
          returnColor = 'blue';
          break;
        case 'WWPSC00004': // 작업결과 승인중
          returnColor = 'yellow';
          break;
        case 'WWPSC00003': // 작업진행중
          returnColor = 'deep-purple-6';
          break;
        case 'WWPSC00009': // 작업완료
          returnColor = 'green';
          break;
        case 'WWPSC99999': // 작업취소
        case 'WWPSC99998': // 작업계획취소
          returnColor = 'red-6';
          break;
      }
      return returnColor;
    },
  }
};
</script>
<style lang="sass">
.no-padding
  padding: 0px 0px 6px 0px !important
.maindashboard .cardcontents
  box-shadow: 0 !important
  border: 1px solid rgba(0,0,0,0.12) !important
  border-radius: 10px !important
  margin-top: 6px
  margin-bottom: 6px
  margin-right: 6px
  .card-body
    padding: 10px !important
    border-radius: 0 0 10px 10px !important
    background: #fff
  .card-header:before
    font-family: "Material Icons"
    content: "\e876"
  .card-header
    border-bottom-width: 0px !important
    border-radius: 10px 10px 0 0 !important
    height: 40px
    color: #0072c6
    background: #f8f8f9
    display: inline-block
    padding-top: 8px
    width: 100%
    .card-more
      float: right !important
      cursor: pointer
      color: #d2d2d2
    .card-more:hover
      color: #1ab394
.main-header-input
  margin-top: -5px
  padding-right: 5px
.main-header-input2
  margin-top: -5px
  padding-right: 5px
  width: 270px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.main-header-input2-director
  margin-top: -5px
  padding-right: 5px
  width: 260px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.cardcontents .card-body.nopadgrid
  padding-top: 0px !important
.btn-fixed-width-height
  width: 95%
  margin: 3px !important
  pointer-events: none

.directorMainLayer
  .workPermit-carousel
    border-radius: 10px
    height: var(--map-height) !important
    .q-carousel__slide
      width: calc(var(--map-ratio) * var(--map-height)) !important
      max-width: calc(var(--map-ratio) * var(--map-height)) !important
      position: relative
      // overflow-x: auto
    .q-carousel--padding, .q-carousel__slide
      padding: 0 !important
    .q-carousel__arrow
      transition: all 0.5s
      opacity: 0.2
    .q-carousel__arrow:hover
      transition: all 0.5s
      opacity: 0.8
    // .q-carousel__slides-container
    //   .q-panel.scroll
    //     overflow: hidden !important


.directorMainLayer
  .caption-work2
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #fff
    padding: 10px
  .caption-work
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #555
    margin-top: 4px

.directorMainLayer
  .mainmapstart
    float: right
    margin-right: 20px

.directorMainLayer
  .mainMarkImage
    padding: 0px !important
    // text-align: center
    font-size: 20px
    height: 30px !important
    position: absolute
    padding: 16px
    color: #C10015
    z-index: 3 !important
    // background: rgba(0,0,0,0.47)
    .markImage-abbreviation-parent
      // width:240px
      text-align: start
      position: absolute
      .markImage-abbreviation
        cursor: pointer
        display: inline-block
        // min-width:60px
        // max-width:250px
        width: auto
        border-left: 1px solid #757575
        border-right: 1px solid #757575
        border-top: 1px solid #757575
        border-bottom: 1px solid #757575
        border-radius: 5px
        .markImage-tran
          border-radius: 5px
        .markImage-abbreviation-banner
          border-right: 1px solid #757575
          display: inline-block
          text-align: center
          min-width:60px
          padding: 1px !important
          border-radius: 5px 0px 0px 5px
          i
            font-size: 20px
          div
            font-size: 12px !important
        .markImage-abbreviation-banner.last-banner
          border-right: 0
          border-radius: 0px 5px 5px 0px
        .markImage-abbreviation-plus
          display: inline-block
          text-align: center
          min-width:40px
        .markImage-abbreviation-banner-detail
          border-radius: 5px
          .q-banner__avatar i
            font-size: 20px !important
          .q-banner__content
            font-size: 12px !important

.directorMainLayer
  .markImage-abbreviation > div
    -webkit-animation: fadeInFromNone 0.1s ease-out
    -moz-animation: fadeInFromNone 0.1s ease-out
    -o-animation: fadeInFromNone 0.1s ease-out
    animation: fadeInFromNone 0.1s ease-out


.main-map-card 
  border-radius: 10px !important
  border-width: 0px !important
  background-color: #161d31 !important
  .q-tab--inactive
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
  .q-tab--active
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
    background-color: #fff !important
    color:  #161d31 !important
  .q-tab__indicator
    display: none !important
  .customCardbody
    margin: 0px !important
    .main-card-head-text
      font-weight: 600
      font-size: 0.9rem
      line-height: 26px
  .cardhead
    height: 41px !important
    background-color: #343d55 !important
    color: #fff !important
    padding-top: 5px !important
    font-size: 1.1em !important
  .customCardbody
    background-color: #283046 !important
    border-radius: 0 0 10px 10px !important
    padding-top: 10px !important
  .q-separator--horizontal
    display: none !important
  .mainDashboardTab
    .q-tab-panel
      display: table
      width: 100%
    .q-tab__label
      font-size: 1.0em !important
      font-weight: 600
  .mainDashboardTabS
    .q-tab__label
      font-size: 1.1em !important
  .maintabTable
    width: 100%
    display: inline-flex
    color: #fff !important
  .maintabTableTop
    border-top: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintabTd
    font-size: 1.1em !important
    padding: 8px 5px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%
  .maintabTr
    font-size: 1.1em !important
    padding: 8px 5px
    width: 100%
    background-color: #161d31 !important
  .maintabTd7
    width: 70%
  .maintabTd6
    width: 60%
  .maintabTd5
    width: 50%
  .maintabTd4
    width: 40%
  .maintabTd3
    width: 30%
    text-align: right
  .maintabTd2
    width: 20%
  .maintabTd1
    width: 10%
  .maintabTdgreen
    color: #28c76f !important
    font-size: 1.05em !important
    text-align: center
  .maintablebody
    height: 250px
    background-color: #343d55 !important
    border-radius: 0 0 10px 10px !important
    overflow-y: auto
    border-bottom: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintablebody2
    height: 300px !important

.main-work-permit-card-cursor
  cursor: pointer
.main-work-permit-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  .main-work-permit-card-section
    padding: 0 10px 0 10px
  .main-work-permit-card-item
    min-height: auto
.mainMapMenuLayer
  border-radius: 10px !important
  max-width: 600px !important
.main-work-permit-card-proc
  margin-right: 6px !important
.main-work-permit-card-equip, .main-work-permit-card-proc
  display: inline-block
  padding-top: 4px !important
  padding-bottom: 4px !important
</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
.my-class-direct {
  /* background-color: #9E9E9E; */
  border: 2px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(40, 48, 70, 0.2);
  border-radius: 10px;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
  color: black;
  cursor: pointer;
  /* z-index: 1 !important; */
}
.my-class-direct div {
  width: 100%;
  border-radius: 10px 10px 0 0;
  color: #fff;
  background-color: rgba(40, 48, 70, 0.6);
}
.my-active-class {
  /* opacity: 0.5; */
  background-color: rgba(115,103,240,0.5);
  /* color:azure; */
}
.chip-task {
  margin-left: 8px !important;
}
.app-main__inner .task-detail {
  z-index: 5;
  width:250px;
  position: relative;
}
.app-main__inner .task-detail .q-banner {
  background-color: #283046;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0px !important;
  color: #fff !important;
}
.app-main__inner .task-detail .q-banner .col-auto {
  padding: 0px !important;
}
.mainmapbannerTitle {
  width: 100%;
  color: #fff;
  background-color: #343d55;
  padding: 10px !important;
  border-radius: 3px;
  font-size: 0.9em !important;
}
.open-task {
  left: 100%; 
  width: 80%;
  transform: translate(-100%, 0);
  position: absolute;
  z-index: 1000;
  padding: 10px !important;
  border-radius: 15px !important;
  background: #fff;
}
.maintablebody::-webkit-scrollbar {
  width: 10px;
}
.maintablebody::-webkit-scrollbar-track {
  background: #343d55 !important;
  border-radius: 0 0 10px 0;
}

.maintablebody::-webkit-scrollbar-thumb {
  background-color: #161d31 !important;
  background-image: linear-gradient(315deg, #161d31 0%, #161d31 74%);
}
.link-line {
  text-decoration: underline;
  cursor: pointer;
}
.mapTitleDivDirector {
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 10px;
  background-color: #5e5f61 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 9999;
  font-size: 12px;
}
.main-work-permit-card-light-blue {
  border-top: 2px solid #03a9f4;
}
.main-work-permit-card-amber {
  border-top: 2px solid #ffc107;
}
.main-work-permit-card-light-green {
  border-top: 2px solid #8bc34a;
}
</style>